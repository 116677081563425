import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";

const CoffeeWidget = () => {
  const [animationData, setAnimationData] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // Controls popup visibility
  const [widgetBottom, setWidgetBottom] = useState("18px"); // Controls widget position

  useEffect(() => {
    // Fetch the animation JSON from the public folder
    fetch(`${process.env.PUBLIC_URL}/assets/CoffeeAnimation2.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setAnimationData(data))
      .catch((error) => console.error("Failed to load animation:", error));

    // Adjust widget position based on footer visibility
    const adjustWidgetPosition = () => {
      const footer = document.querySelector("footer");
      if (footer) {
        const footerTop = footer.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        // If the footer is visible, adjust the widget's position
        if (footerTop < viewportHeight) {
          setWidgetBottom(`${viewportHeight - footerTop + 18}px`);
        } else {
          setWidgetBottom("18px");
        }
      }
    };

    // Add scroll and resize event listeners
    window.addEventListener("scroll", adjustWidgetPosition);
    window.addEventListener("resize", adjustWidgetPosition);

    // Adjust position on initial load
    adjustWidgetPosition();

    return () => {
      // Cleanup event listeners on component unmount
      window.removeEventListener("scroll", adjustWidgetPosition);
      window.removeEventListener("resize", adjustWidgetPosition);
    };
  }, []);

  if (!animationData) return null; // Wait for the animation to load

  const toggleWidget = () => {
    setIsOpen((prev) => !prev); // Toggle popup visibility
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: widgetBottom, // Dynamic bottom position
        right: "18px",
        zIndex: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        transition: "bottom 0.3s ease", // Smooth position transitions
      }}
    >
      {/* Floating Button with Lottie Animation */}
      <div
        style={{
          cursor: "pointer",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
        }}
        onClick={toggleWidget}
        aria-label="Buy Me a Coffee"
      >
        <Lottie
          animationData={animationData}
          loop
          autoplay
          style={{
            width: "60px",
            height: "60px",
          }}
        />
      </div>

      {/* Widget Content */}
      {isOpen && (
        <div
          style={{
            position: "absolute",
            bottom: "80px", // Position popup above the button
            right: "0px",
            backgroundColor: "#fff",
            color: "#333",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "16px",
            width: "300px",
            textAlign: "center",
            zIndex: 99, // Higher than the button
          }}
        >
          <h4 style={{ margin: "0 0 8px" }}>Support us on Buy Me a Coffee!</h4>
          <p style={{ margin: "0 0 16px", fontSize: "14px" }}>
            Thank you for visiting our site. Here you can buy us a coffee.
          </p>
          <a
            href="https://www.buymeacoffee.com/craftsdb"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: "#0e97cf",
              color: "#fff",
              textDecoration: "none",
              padding: "10px 20px",
              borderRadius: "4px",
              display: "inline-block",
              fontWeight: "bold",
            }}
          >
            Buy Us a Coffee
          </a>
        </div>
      )}
    </div>
  );
};

export default CoffeeWidget;
