import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DarkMode, LightMode, Menu, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const [isTabActive, setIsTabActive] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isSmallScreen = useMediaQuery("(max-width: 999px)");
  const theme = useTheme();

  // Dynamically set background color for dark and light modes
  const background =
    theme.palette.mode === "dark"
      ? "#222428"
      : theme.palette.background.default;
  const alt = theme.palette.background.alt;

  // Monitor tab visibility to pause/resume animation
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Gradient styles with hover effect and animation control
  const gradientTextStyles = {
    position: "relative",
    display: "inline-block",
    textDecoration: "none",
    color: "#33ccff", // Default text color
    "&:hover": {
      background:
        "linear-gradient(90deg, #33ccff, #00aaff, #0077ff, #002ea3, #33ccff)", // Gradient animation on hover
      backgroundSize: "300% 100%",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      animation: isTabActive
        ? "moveGradient 2.2s ease-in-out infinite"
        : "none",
      "@keyframes moveGradient": {
        "0%": { backgroundPosition: "0% 50%" },
        "50%": { backgroundPosition: "100% 50%" },
        "100%": { backgroundPosition: "0% 50%" },
      },
    },
    cursor: "pointer",
    transition: "filter 0.3s ease",
  };

  return (
    <FlexBetween padding="1rem 6%" backgroundColor={background}>
      {/* Replace Logo with "Crafts DB" Text */}
      <Box
        onClick={() => navigate("/home")}
        sx={{
          "&:hover": {
            cursor: "pointer",
            filter: "brightness(70%)",
          },
        }}
        className="logo"
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          fontSize="1.5rem"
          sx={gradientTextStyles}
        >
          Crafts DB
        </Typography>
      </Box>

      {/* New Menu Items for Desktop */}
      {!isSmallScreen && (
        <FlexBetween gap="2rem" alignItems="center">
          {["Crafts Database", "Community Crafts", "Donate", "Partners"].map(
            (text, index) => (
              <Typography
                key={index}
                fontWeight="bold"
                fontSize="clamp(1rem, 1.3rem, 1.25rem)"
                onClick={() => {
                  const route =
                    text === "Crafts Database"
                      ? "/crafts" // Specific route for Crafts Database
                      : `/${text.toLowerCase().replace(/ /g, "-")}`;
                  navigate(route);
                }}
                sx={gradientTextStyles}
              >
                {text}
              </Typography>
            )
          )}

          {/* Dark Mode Toggle */}
          <IconButton
            onClick={() => {
              dispatch(setMode());
              setIsMobileMenuToggled(!isMobileMenuToggled);
            }}
          >
            {theme.palette.mode === "dark" ? (
              <DarkMode
                sx={{
                  color: "#fff",
                  fontSize: "25px",
                }}
              />
            ) : (
              <LightMode sx={{ color: "#000", fontSize: "25px" }} />
            )}
          </IconButton>
        </FlexBetween>
      )}

      {/* Burger Menu for Small Screens */}
      {isSmallScreen && (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          {isMobileMenuToggled ? <Close /> : <Menu />}
        </IconButton>
      )}

      {/* Mobile Navigation */}
      {isSmallScreen && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          backgroundColor={background}
        >
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="1rem"
          >
            {["Crafts Database", "Community Crafts", "Donate", "Partners"].map(
              (text, index) => (
                <Typography
                  key={index}
                  fontWeight="bold"
                  fontSize="clamp(1rem, 1.3rem, 1.25rem)"
                  onClick={() => {
                    const route =
                      text === "Crafts Database"
                        ? "/crafts" // Specific route for Crafts Database
                        : `/${text.toLowerCase().replace(/ /g, "-")}`;
                    navigate(route);
                  }}
                  sx={gradientTextStyles}
                >
                  {text}
                </Typography>
              )
            )}

            {/* Dark Mode Toggle */}
            <IconButton
              onClick={() => {
                dispatch(setMode());
              }}
            >
              {theme.palette.mode === "dark" ? (
                <DarkMode
                  sx={{
                    color: "#fff",
                    fontSize: "25px",
                  }}
                />
              ) : (
                <LightMode sx={{ color: "#000", fontSize: "25px" }} />
              )}
            </IconButton>
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default Navbar;
