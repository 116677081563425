import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Breadcrumbs, Link } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";

const CategoryPage = () => {
  const { item } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedSticker = searchParams.get("sticker");

  const [skins, setSkins] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [loadingAnimationData, setLoadingAnimationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/LoadingAnimation.json")
      .then((response) => response.json())
      .then((data) => setLoadingAnimationData(data))
      .catch((error) => console.error("Error loading animation:", error));
  }, []);

  useEffect(() => {
    const loadSkins = async () => {
      try {
        const response = await fetch(
          "http://localhost:5000/api/weapons-with-stickers"
        );
        const data = await response.json();
        setSkins(data);
      } catch (error) {
        console.error("Error fetching skins:", error);
      }
    };

    const loadQuantities = async () => {
      try {
        const response = await fetch("http://localhost:5000/api/quantities");
        const data = await response.json();
        const quantityMap = {};
        data.forEach((item) => {
          quantityMap[item.item_name] = item.quantity;
        });
        setQuantities(quantityMap);
      } catch (error) {
        console.error("Error fetching quantities:", error);
      }
    };

    setIsLoading(true);
    Promise.all([loadSkins(), loadQuantities()]).then(() =>
      setIsLoading(false)
    );
  }, [item]);

  const handleOpenSite = (skin) => {
    // Extract required values directly from the skin object
    const name = encodeURIComponent(skin.weapon_name); // From skins table
    const defIndex = skin.def_index; // Correctly mapped from defID table
    const paintIndex = skin.paint_index; // Correctly mapped from skins table
    const min = skin.min_float; // Correctly mapped from skins table
    const max = skin.max_float; // Correctly mapped from skins table
    const stickers = encodeURIComponent(
      `[{"i":"${selectedSticker}"}]` // Sticker ID from stickers table
    );

    // Validation to ensure all necessary fields are available
    if (
      defIndex === undefined ||
      paintIndex === undefined ||
      min === undefined ||
      max === undefined
    ) {
      console.error("Missing required fields for URL generation:", {
        defIndex,
        paintIndex,
        min,
        max,
      });
      return;
    }

    // Generate URL
    const url = `https://csfloat.com/db?name=${name}&defIndex=${defIndex}&paintIndex=${paintIndex}&min=${min}&max=${max}&stickers=${stickers}`;
    window.open(url, "_blank");
  };

  const filteredSkins = skins.filter(
    (skin) =>
      skin.weapon_name.toLowerCase().includes(item.toLowerCase()) &&
      skin.sticker_name === selectedSticker
  );

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <h1>Testing Category Page</h1>

      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" onClick={() => navigate("/")}>
          Home
        </Link>
        <Link color="inherit" onClick={() => navigate("/crafts")}>
          Crafts
        </Link>
        <Link
          color="inherit"
          onClick={() => navigate(`/weapons?sticker=${selectedSticker}`)}
        >
          Weapon Categories
        </Link>
        <Typography color="textPrimary">{item.toUpperCase()}</Typography>
      </Breadcrumbs>

      <Typography variant="h4" gutterBottom>
        {item.toUpperCase()} SKINS with {selectedSticker}
      </Typography>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          {loadingAnimationData && (
            <Lottie options={lottieOptions} height={200} width={200} />
          )}
        </Box>
      ) : filteredSkins.length === 0 ? (
        <Typography>
          No skins found for {item.toUpperCase()} with {selectedSticker}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {filteredSkins.map((skin) => {
            let hoverColor = "inherit";
            if (skin.iOwn) {
              hoverColor = "#04A777";
            } else if (skin.doesntExist) {
              hoverColor = "#F67E7D";
            }

            return (
              <Grid item key={skin.id} xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                    "&:hover img": {
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => handleOpenSite(skin)}
                >
                  <img
                    src={skin.image_url || "/default-vanilla-image.jpg"}
                    alt={skin.weapon_name}
                    style={{
                      width: "180px",
                      height: "auto",
                      transition: "transform 0.3s",
                    }}
                  />
                  <Typography variant="h5">{skin.weapon_name}</Typography>
                  <Typography variant="body2">
                    Quantity: {quantities[skin.weapon_name] || 0}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default CategoryPage;
