import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import HomePage from "scenes/homePage";
// import LoginPage from "scenes/loginPage";
// import ProfilePage from "scenes/profilePage";
import Inventory from "Inventory";
// import Members from "scenes/Members";
import TwitterFeed from "TwitterFetcher/csTwitterCrafts";
import CategoryPage from "category";
// import Ak47 from "Weapons/ak47";
import Crafts from "Crafts/Crafts";
import WeaponsCategories from "Weapons/WeaponsCategories";
import Weapons from "Weapons/Weapons";
import { themeSettings } from "./theme";
import DonationForm from "Donation/DonationPage";
import Partners from "Partners/Partners";

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            {/* <Route path="/profile/:userId" element={<ProfilePage />} /> */}
            {/* <Route path="/login" element={<LoginPage />} /> */}
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/category/:item" element={<CategoryPage />} />
            {/* <Route path="/category/ak47" element={<Ak47 />} /> */}
            {/* <Route path="/members" element={<Members />} /> */}
            <Route path="/community-crafts" element={<TwitterFeed />} />
            <Route path="/crafts" element={<Crafts />} />
            <Route path="/donate" element={<DonationForm />} />
            <Route path="/partners" element={<Partners />} />

            {/* Updated route structure for weapons */}
            <Route path="/weapons" element={<WeaponsCategories />} />
            <Route path="/weapons/:category" element={<Weapons />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
