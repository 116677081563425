import React from "react";
import Navbar from "scenes/navbar";
import Footer from "scenes/footer";
import "./DonationForm.css";
import { FaPaypal, FaCreditCard, FaUniversity } from "react-icons/fa";
import { SiMastercard, SiVisa } from "react-icons/si";

const DonationForm = () => {
  const handleDonateClick = () => {
    window.open(
      "https://www.paypal.com/donate/?hosted_button_id=2W4AENHGB34CU",
      "_blank"
    );
  };

  const handleTradeOfferClick = () => {
    window.open(
      "https://steamcommunity.com/tradeoffer/new/?partner=311738958&token=GXVJjLTK",
      "_blank"
    ); // Replace with your trade offer link
  };

  return (
    <div className="donation-page gridBackground">
      <Navbar />

      <div className="donation-content-wrapper">
        <div className="donation-container">
          <h1 className="animated-title">
            By Supporting Us You Help Us Maintain And Update The Page
          </h1>
          <p className="animated-subtitle">
            Your donation makes a difference. Use the secure button below to
            support us.
          </p>

          {/* Custom Donate Button */}
          <div className="custom-donate-button">
            <button onClick={handleDonateClick} className="donate-button">
              Donate Now
            </button>
          </div>

          {/* Payment Icons */}
          <div className="payment-icons">
            <FaPaypal className="icon paypal-icon" title="PayPal" />
            <SiVisa className="icon visa-icon" title="Visa" />
            <SiMastercard className="icon mastercard-icon" title="Mastercard" />
            <FaCreditCard
              className="icon creditcard-icon"
              title="Credit Card"
            />
            <FaUniversity className="icon bank-icon" title="Bank Transfer" />
          </div>

          {/* OR Section */}
          <div className="or-section">
            <span className="or-text">OR</span>
          </div>

          {/* Send Me A Trade Offer Button */}
          <div className="custom-donate-button">
            <button onClick={handleTradeOfferClick} className="donate-button">
              Send Me A Trade Offer
            </button>
          </div>

          {/* OR Section */}
          <div className="or-section">
            <span className="or-text">OR</span>
          </div>

          {/* Buy Me A Coffee Embed */}
          <div className="coffee-section centered-embed">
            <a
              href="https://www.buymeacoffee.com/craftsdb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=craftsdb&button_colour=005d9b&font_colour=ffffff&font_family=Cookie&outline_colour=ffffff&coffee_colour=FFDD00"
                alt="Buy Me a Coffee"
              />
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DonationForm;
