import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Button,
  ButtonGroup,
  Collapse,
  IconButton,
  InputBase,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Lottie from "react-lottie";
import Navbar from "scenes/navbar";
import Footer from "scenes/footer";
import CoffeeWidget from "BuyMeACofeeWidget/coffeeWidget";
import "./WeaponsCategories.css";
import NoShadowButtonGroup from "NoShadow/NoShadowGrouping";

const weaponCategories = {
  All: [],
  Pistols: [
    "CZ75",
    "Desert Eagle",
    "Dual Berettas",
    "Five-SeveN",
    "Glock-18",
    "P2000",
    "P250",
    "R8 Revolver",
    "Tec-9",
    "USP-S",
  ],
  SMGs: ["MAC-10", "MP5-SD", "MP7", "MP9", "PP-Bizon", "P90", "UMP-45"],
  Shotguns: ["MAG-7", "Nova", "Sawed-Off", "XM1014"],
  Heavy: ["M249", "Negev"],
  Rifles: [
    "AK-47",
    "M4A1-S",
    "M4A4",
    "Galil AR",
    "Famas",
    "SG 553",
    "Aug",
    "SSG 08",
    "AWP",
    "G3SG1",
    "SCAR-20",
  ],
};

const WeaponsCategories = () => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [loadingAnimationData, setLoadingAnimationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [stickerName, setStickerName] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sticker = searchParams.get("sticker");
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");

  const fetchAnimation = async () => {
    try {
      const response = await fetch("/LoadingAnimation.json");
      const data = await response.json();
      setLoadingAnimationData(data);
    } catch (error) {
      console.error("Error loading animation:", error);
    }
  };

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      if (!sticker) throw new Error("Sticker ID is missing.");

      const response = await fetch(
        `http://api.krisgfx.com/api/weapons/categories?sticker=${sticker}`
      );
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      const sortedCategories = data.sort(
        (a, b) => a.weapon_order - b.weapon_order
      );
      setCategories(sortedCategories);
      setFilteredCategories(sortedCategories);
    } catch (error) {
      console.error("Error fetching weapon categories:", error);
      setCategories([]);
      setFilteredCategories([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStickerName = async () => {
    try {
      const response = await fetch("http://api.krisgfx.com/api/stickers");
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      const stickerData = data.find((item) => item.id === parseInt(sticker));
      if (stickerData) {
        const name = stickerData.name.replace("Sticker |", "").trim();
        setStickerName(name);
      } else {
        setStickerName("Unknown Sticker");
      }
    } catch (error) {
      console.error("Error fetching sticker name:", error);
      setStickerName("Unknown Sticker");
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setIsSearching(false);
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await fetch(
        `http://api.krisgfx.com/api/crafts/search?query=${encodeURIComponent(
          searchQuery
        )}&sticker=${sticker || ""}`
      );
      const data = await response.json();

      const updatedResults = data.map((item) => {
        if (
          item.weapon_name &&
          typeof item.weapon_name === "string" &&
          !item.weapon_name.includes("Vanilla")
        ) {
          const name = encodeURIComponent(item.weapon_name);
          const defIndex = item.def_index || "unknown";
          const paintIndex = item.paint_index || "unknown";
          const min = item.min_float || 0;
          const max = item.max_float || 0;
          const stickers = JSON.stringify(
            [
              item.sticker1,
              item.sticker2,
              item.sticker3,
              item.sticker4,
              item.sticker5,
            ]
              .filter(Boolean) // Only include non-null stickers
              .map((id) => ({ i: id }))
          );

          item.csfloatLink = `https://csfloat.com/db?name=${name}&defIndex=${defIndex}&paintIndex=${paintIndex}&min=${min}&max=${max}&stickers=${encodeURIComponent(
            stickers
          )}`;
        }
        return item;
      });

      setSearchResults(updatedResults);
    } catch (error) {
      console.error("Error searching crafts:", error);
      setSearchResults([]);
    }
  };

  useEffect(() => {
    fetchAnimation();
    if (sticker) {
      fetchCategories();
      fetchStickerName();
    }
  }, [sticker]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleSearch();
    }, 300); // Debounce input
    return () => clearTimeout(timeout);
  }, [searchQuery]);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    if (filter === "All") {
      setFilteredCategories([...categories]);
    } else {
      const filtered = categories.filter((category) =>
        weaponCategories[filter].includes(category.category)
      );
      setFilteredCategories(filtered);
    }
  };

  const handleCategoryClick = (category) => {
    navigate(`/weapons/category?sticker=${sticker}&category=${category}`);
  };

  return (
    <Box
      className="weapons-grid-background"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <Box
        sx={{
          padding: "2rem",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Button
            sx={{
              mb: 2,
              fontSize: "2rem",
              width: "88px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: 0,
            }}
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
          >
            &#8592;
          </Button>

          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => setIsSearchOpen(!isSearchOpen)}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
              }}
            >
              {isSearchOpen ? <CloseIcon /> : <SearchIcon />}
            </IconButton>
            {isSearchOpen && (
              <Box
                sx={{
                  ml: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  overflow: "hidden",
                }}
              >
                <InputBase
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search crafts..."
                  sx={{
                    width: "200px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>

        {isMobile ? (
          <>
            <Button
              onClick={() => setIsMenuOpen((prev) => !prev)}
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
            >
              Choose A Category
            </Button>
            <Collapse in={isMenuOpen}>
              <NoShadowButtonGroup
                orientation="vertical"
                sx={{ width: "100%", mb: 2 }}
                variant="contained"
                color="primary"
              >
                <Button onClick={() => handleFilterChange("All")}>All</Button>
                <Button onClick={() => handleFilterChange("Pistols")}>
                  Pistols
                </Button>
                <Button onClick={() => handleFilterChange("Rifles")}>
                  Rifles
                </Button>
                <Button onClick={() => handleFilterChange("SMGs")}>SMGs</Button>
                <Button onClick={() => handleFilterChange("Shotguns")}>
                  Shotguns
                </Button>
                <Button onClick={() => handleFilterChange("Heavy")}>
                  Heavy
                </Button>
              </NoShadowButtonGroup>
            </Collapse>
          </>
        ) : (
          <NoShadowButtonGroup
            sx={{ mb: 3 }}
            variant="contained"
            color="primary"
          >
            <Button onClick={() => handleFilterChange("All")}>All</Button>
            <Button onClick={() => handleFilterChange("Pistols")}>
              Pistols
            </Button>
            <Button onClick={() => handleFilterChange("Rifles")}>Rifles</Button>
            <Button onClick={() => handleFilterChange("SMGs")}>SMGs</Button>
            <Button onClick={() => handleFilterChange("Shotguns")}>
              Shotguns
            </Button>
            <Button onClick={() => handleFilterChange("Heavy")}>Heavy</Button>
          </NoShadowButtonGroup>
        )}

        <Typography variant="h4" gutterBottom>
          Skins with Sticker: {stickerName}
        </Typography>

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            {loadingAnimationData && (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loadingAnimationData,
                  rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
                }}
                height={200}
                width={200}
              />
            )}
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
            {(isSearching ? searchResults : filteredCategories).map(
              (item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card
                    sx={{
                      cursor: "pointer",
                      transition: "background-color 0.3s, transform 0.3s",
                      position: "relative",
                      padding: "1rem",
                      borderRadius: "12px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        transform: "translateY(-5px)",
                        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "375px",
                      textAlign: "center",
                    }}
                    onClick={
                      isSearching
                        ? undefined
                        : () => handleCategoryClick(item.category)
                    }
                  >
                    <CardMedia
                      component="img"
                      image={
                        item.vanilla_image ||
                        item.image_url ||
                        "/default-vanilla-image.jpg"
                      }
                      alt={item.category || item.weapon_name}
                      sx={{
                        height: "150px",
                        width: "auto",
                        marginBottom: "0.5rem",
                        objectFit: "contain",
                      }}
                    />
                    <CardContent sx={{ padding: 0 }}>
                      <Typography variant="h6">
                        {item.category || item.weapon_name}
                      </Typography>
                      {item.weapon_name &&
                        !item.weapon_name.includes("Vanilla") &&
                        (item.quantity === 0 ? (
                          <Typography variant="body2" color="textSecondary">
                            Doesn't exist yet
                          </Typography>
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            Quantity: {item.quantity}
                          </Typography>
                        ))}
                      {item.csfloatLink && (
                        <Button
                          onClick={() =>
                            window.open(item.csfloatLink, "_blank")
                          }
                          variant="outlined"
                          sx={{ mt: 1 }}
                        >
                          View on CSFloat
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )
            )}
          </Grid>
        )}
      </Box>
      <CoffeeWidget />
      <Footer />
    </Box>
  );
};

export default WeaponsCategories;
