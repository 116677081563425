import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // useParams to dynamically get fileName
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";

const Crafts1 = () => {
  const { fileName } = useParams(); // Dynamically get the file name (e.g., sticker-102)
  const [stickersData, setStickersData] = useState([]);
  const [matchedSticker, setMatchedSticker] = useState(null); // Store the matched sticker object
  const [selectedSticker, setSelectedSticker] = useState("");
  const navigate = useNavigate();

  // Fetch stickers.json and match based on the dynamic file name
  useEffect(() => {
    const fetchStickersData = async () => {
      try {
        const response = await fetch("/stickers.json"); // Ensure correct path to stickers.json
        const stickers = await response.json();
        setStickersData(stickers);

        // Match the dynamic file name (like sticker-102) to the sticker ID in stickers.json
        const matchingSticker = stickers.find(
          (sticker) => sticker.id === fileName
        ); // Match by dynamic file name

        if (matchingSticker) {
          setMatchedSticker(matchingSticker);
        } else {
          console.error(`No matching sticker found for ${fileName}`);
        }
      } catch (error) {
        console.error("Error fetching stickers.json:", error);
      }
    };

    fetchStickersData();
  }, [fileName]); // Depend on fileName to re-run if the fileName changes

  // Handle sticker selection and navigate to weapon list
  const handleStickerSelect = (event) => {
    const selectedSticker = event.target.value;
    setSelectedSticker(selectedSticker);

    // Navigate to the weapons list page with the selected sticker as a query param
    if (selectedSticker) {
      navigate(`/weapons?sticker=${selectedSticker}`);
    }
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <Typography variant="h4" gutterBottom>
        Select a Sticker for Crafts
      </Typography>

      {/* Sticker Dropdown */}
      <FormControl fullWidth>
        <Select
          value={selectedSticker}
          onChange={handleStickerSelect}
          displayEmpty
          variant="outlined"
        >
          <MenuItem value="">
            <em>Select a Sticker</em>
          </MenuItem>
          {matchedSticker && (
            <MenuItem value={matchedSticker.name}>
              {matchedSticker.name}
            </MenuItem>
          )}
        </Select>
      </FormControl>

      {/* Display matched sticker */}
      {matchedSticker && (
        <Box sx={{ marginTop: "2rem" }}>
          <Card sx={{ display: "flex", marginBottom: "1rem" }}>
            <CardMedia
              component="img"
              sx={{ width: 100 }}
              image={matchedSticker.image}
              alt={matchedSticker.name}
            />
            <CardContent>
              <Typography variant="h5">{matchedSticker.name}</Typography>
              <Typography variant="body2">
                {matchedSticker.description}
              </Typography>
              <Typography variant="body2" color="primary">
                Rarity: {matchedSticker.rarity.name} (
                {matchedSticker.rarity.color})
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default Crafts1;
