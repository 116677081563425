import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import Lottie from "react-lottie";
import Navbar from "scenes/navbar"; // Assuming this is the correct path
import Footer from "scenes/footer"; // Assuming this is the correct path
import CoffeeWidget from "BuyMeACofeeWidget/coffeeWidget"; // Import CoffeeWidget

const Crafts = () => {
  const [stickers, setStickers] = useState([]);
  const [loadingAnimationData, setLoadingAnimationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/LoadingAnimation.json")
      .then((response) => response.json())
      .then((data) => setLoadingAnimationData(data))
      .catch((error) => console.error("Error loading animation:", error));
  }, []);

  useEffect(() => {
    const fetchStickerData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("http://api.krisgfx.com/api/stickers");
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setStickers(data);
      } catch (error) {
        console.error("Error fetching stickers:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStickerData();
  }, []);

  const handleStickerClick = (stickerId) => {
    navigate(`/weapons?sticker=${stickerId}`);
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  return (
    <div
      className="gridBackground"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Navbar />
      <Box sx={{ flex: 1, padding: "2rem" }}>
        <Typography variant="h4" gutterBottom>
          Select a Sticker for to view its crafts.
        </Typography>

        {isLoading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", height: "70vh" }}
          >
            {loadingAnimationData && (
              <Lottie options={lottieOptions} height={200} width={200} />
            )}
          </Box>
        ) : (
          <Grid container spacing={2}>
            {stickers.length > 0 ? (
              stickers.map((sticker) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={sticker.id}>
                  <Card
                    sx={{
                      cursor: "pointer",
                      transition: "background-color 0.3s, transform 0.3s",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        transform: "scale(1.05)",
                      },
                      padding: "0.5rem", // Adjusted padding to resize cards
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    onClick={() => handleStickerClick(sticker.id)}
                  >
                    <CardMedia
                      component="img"
                      image={sticker.image_url}
                      alt={sticker.name}
                      sx={{ width: "80%", height: "auto" }} // Reduced width
                    />
                    <CardContent>
                      <Typography variant="body1">{sticker.name}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body2" color="error">
                No stickers found.
              </Typography>
            )}
          </Grid>
        )}
      </Box>
      {/* Coffee Widget */}
      <CoffeeWidget />
      {/* Footer Stays at Bottom */}
      <Footer sx={{ marginTop: "auto" }} />
    </div>
  );
};

export default Crafts;
