import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  IconButton,
  Grid,
  Card,
  CardMedia,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Lottie from "react-lottie";
import checkboxAnimation from "./checkbox.json"; // Replace with the correct path to checkbox.json
import Navbar from "scenes/navbar"; // Assuming Navbar exists in the correct path
import Footer from "scenes/footer"; // Assuming Footer exists

const SubmitCraft = () => {
  const [open, setOpen] = useState(false);
  const [openRankingModal, setOpenRankingModal] = useState(false);
  const [ownerName, setOwnerName] = useState("");
  const [description, setDescription] = useState("");
  const [skinName, setSkinName] = useState("");
  const [stickers, setStickers] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [crafts, setCrafts] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [expandedImage, setExpandedImage] = useState(null);

  // Fetch crafts from the server on load
  useEffect(() => {
    const fetchCrafts = async () => {
      try {
        const response = await fetch("http://localhost:5000/api/crafts");
        if (!response.ok) {
          throw new Error("Failed to fetch crafts.");
        }
        const data = await response.json();
        setCrafts(data.filter((craft) => craft.status === "accepted")); // Filter only accepted crafts
      } catch (err) {
        console.error("Error fetching crafts:", err.message);
      }
    };

    fetchCrafts();
  }, []);

  const fetchRankings = async () => {
    try {
      const response = await fetch(
        "http://localhost:5000/api/discord-rankings"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch rankings.");
      }
      const data = await response.json();
      setRankings(data);
    } catch (err) {
      console.error("Error fetching rankings:", err.message);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOwnerName("");
    setDescription("");
    setSkinName("");
    setStickers("");
    setImage(null);
    setError("");
    setShowAnimation(false);
  };

  const handleOpenRankingModal = async () => {
    setOpenRankingModal(true);
    await fetchRankings();
  };

  const handleCloseRankingModal = () => setOpenRankingModal(false);

  const handleExpandImage = (image) => setExpandedImage(image);
  const closeExpandedImage = () => setExpandedImage(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const invalidChars = /[^a-zA-Z0-9._-]/;

    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setError("Image size must be 2MB or less.");
        setImage(null);
      } else if (invalidChars.test(file.name) || file.name.includes(" ")) {
        setError("File names cannot contain spaces or special characters.");
        setImage(null);
      } else {
        setImage(file);
        setError("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ownerName || !description || !skinName || !image) {
      setError("All fields except Stickers are required.");
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("ownerName", ownerName);
    formData.append("description", description);
    formData.append("skinName", skinName);
    formData.append("stickers", stickers);
    formData.append("image", image);

    try {
      const response = await fetch("http://localhost:5000/api/submit-craft", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Backend response:", errorText);
        throw new Error("Failed to submit craft.");
      }

      setShowAnimation(true);

      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (err) {
      console.error("Error submitting craft:", err.message);
      setError("An error occurred while submitting the craft.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "1rem",
        }}
      >
        <Typography variant="h4">Community Crafts</Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenRankingModal}
            sx={{ mr: 2 }}
          >
            Current Rankings
          </Button>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Submit a Craft
          </Button>
        </Box>
      </Box>
      <Box sx={{ padding: "2rem", minHeight: "calc(100vh - 200px)" }}>
        <Grid container spacing={3}>
          {crafts.map((craft, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  position: "relative",
                  borderRadius: "12px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  "&:hover": {
                    boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={() => handleExpandImage(craft.imageUrl)}
              >
                <CardMedia
                  component="img"
                  image={craft.imageUrl || "/placeholder.png"}
                  alt={craft.skinName || "Craft Image"}
                  sx={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    padding: "0.2em 0.4em",
                    borderRadius: "0.4em",
                    fontSize: "0.9rem",
                  }}
                >
                  {craft.skinName || "Unknown Skin"}
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    padding: "0.2em 0.4em",
                    borderRadius: "0.4em",
                    fontSize: "0.9rem",
                  }}
                >
                  {craft.ownerName || "Anonymous"}
                </Box>
                <Box sx={{ p: "1rem", textAlign: "center" }}>
                  {craft.description && (
                    <Typography variant="body2" color="textSecondary">
                      {craft.description}
                    </Typography>
                  )}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* Rankings Modal */}
      <Modal open={openRankingModal} onClose={handleCloseRankingModal}>
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "8px",
            p: 4,
            maxHeight: "80%",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" textAlign="center" gutterBottom>
            Current Rankings
          </Typography>
          <List>
            {rankings.map((ranking) => (
              <ListItem key={ranking.rank}>
                <ListItemText
                  primary={`#${ranking.rank}: ${ranking.imageUrl}`}
                  secondary={`${ranking.reactions} Reactions`}
                />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            onClick={handleCloseRankingModal}
            sx={{ mt: 2 }}
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Modal>
      <Modal open={!!expandedImage} onClose={closeExpandedImage}>
        <Box
          sx={{
            outline: "none",
            position: "relative",
            maxWidth: "90%",
            maxHeight: "90%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            p: "1rem",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={closeExpandedImage}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "#fff",
              color: "#000",
              "&:hover": { backgroundColor: "#ddd" },
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={expandedImage}
            alt="Expanded view"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              borderRadius: "8px",
            }}
          />
        </Box>
      </Modal>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" textAlign="center">
            Submit Your Craft
          </Typography>
          {showAnimation ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: checkboxAnimation,
                }}
                height={150}
                width={150}
              />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <TextField
                label="Owner Name"
                fullWidth
                value={ownerName}
                onChange={(e) => setOwnerName(e.target.value)}
                sx={{ mt: 2 }}
              />
              <TextField
                label="Description"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mt: 2 }}
              />
              <TextField
                label="Skin Name"
                fullWidth
                value={skinName}
                onChange={(e) => setSkinName(e.target.value)}
                sx={{ mt: 2 }}
              />
              <TextField
                label="Stickers (Optional)"
                fullWidth
                value={stickers}
                onChange={(e) => setStickers(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Button variant="contained" component="label" sx={{ mt: 2 }}>
                Upload Image
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {image && (
                <Typography sx={{ mt: 1 }}>
                  Selected File: {image.name}
                </Typography>
              )}
              {error && <Typography color="error">{error}</Typography>}
              <Button
                type="submit"
                variant="contained"
                color="success"
                fullWidth
                sx={{ mt: 3 }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </form>
          )}
        </Box>
      </Modal>
      <Footer />
    </>
  );
};

export default SubmitCraft;
