import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faSteam,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css"; // Import CSS for hover animations

const Footer = () => {
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const footer = document.getElementById("footer");
      setFooterHeight(footer.offsetHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer
      id="footer"
      style={{
        ...footerStyle,
        marginTop: "145px", // Keep the margin-top as requested
      }}
    >
      <div style={footerContentStyle}>
        <span style={textStyle}>
          &copy; All rights reserved to{" "}
          <a href="https://craftsdb.krisgfx.com" className="supers">
            CraftsDB
          </a>{" "}
          and{" "}
          <a href="https://twitter.com/krisgfx" className="supers">
            Krisgfx
          </a>
        </span>
        <div style={iconContainerStyle}>
          <a
            href="https://x.com/1of1DB"
            target="_blank"
            rel="noopener noreferrer"
            className="footerIcons"
          >
            <FontAwesomeIcon icon={faTwitter} style={iconStyle} />
          </a>
          <a
            href="https://steamcommunity.com/groups/craftsdb"
            target="_blank"
            rel="noopener noreferrer"
            className="footerIcons"
          >
            <FontAwesomeIcon icon={faSteam} style={iconStyle} />
          </a>
          <a
            href="https://discord.gg/m3XB3u7tBY"
            target="_blank"
            rel="noopener noreferrer"
            className="footerIcons"
          >
            <FontAwesomeIcon icon={faDiscord} style={iconStyle} />
          </a>
        </div>
      </div>
    </footer>
  );
};

const footerStyle = {
  backgroundColor: "#333",
  color: "#fff",
  padding: "1rem",
  width: "100%",
};

const footerContentStyle = {
  display: "flex",
  justifyContent: "space-between", // Keep text and icons apart
  alignItems: "center",
  maxWidth: "1200px",
  margin: "0 auto",
};

const textStyle = {
  fontSize: "14px",
  textAlign: "center",
  flex: 1,
};

const iconContainerStyle = {
  display: "flex",
  gap: "15px",
  marginLeft: "auto", // Push icons further to the right
};

const iconStyle = {
  fontSize: "1.5rem",
  transition: "transform 0.3s ease, color 0.3s ease",
};

export default Footer;
