import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

// Models array with weights for each model
const models = [
  { path: "/models/m9blue.glb", weight: 1 }, // Common model (80% probability)
  { path: "/models/m9doppler.glb", weight: 25 }, // Less common model (15% probability)
  { path: "/models/m9tiger.glb", weight: 25 }, // Super rare model (5% probability)
  { path: "/models/m9emerald.glb", weight: 24 }, // Super rare model (5% probability)
  { path: "/models/m9autotronic.glb", weight: 25 }, // Super rare model (5% probability)
];

// Function to pick a model based on weights
function getRandomModel() {
  const totalWeight = models.reduce((sum, model) => sum + model.weight, 0);
  const randomWeight = Math.random() * totalWeight;

  let cumulativeWeight = 0;
  for (const model of models) {
    cumulativeWeight += model.weight;
    if (randomWeight <= cumulativeWeight) {
      return model.path;
    }
  }
}

function Model({ modelPath }) {
  const modelRef = useRef();
  const { scene } = useGLTF(modelPath);

  // Helper function to convert degrees to radians
  const toRadians = (degrees) => degrees * (Math.PI / 180);

  // Set initial position and rotation
  const rotationDegrees = [-90, -38, 0]; // X: -90°, Y: -38°, Z: 0°
  const [x, y, z] = rotationDegrees.map(toRadians); // Convert to radians
  scene.position.set(0, 0, 0); // Center the model
  scene.rotation.set(x, y, z); // Apply initial rotation in radians
  scene.scale.set(0.4, 0.4, 0.4); // Uniform scaling

  // Rotate the model continuously on the Z-axis
  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.z += 0.01; // Continuous rotation on Z-axis
    }
  });

  return <primitive ref={modelRef} object={scene} />;
}

function ModelViewer() {
  // Start with a random model based on weighted selection
  const [currentModel] = useState(getRandomModel);

  return (
    <Canvas
      style={{ width: "100%", height: "100%" }}
      camera={{ position: [0, 0, 10], fov: 40 }}
    >
      {/* Lighting */}
      <ambientLight intensity={0.6} />
      <directionalLight position={[5, 7, 150]} intensity={1} />

      {/* Random Model */}
      <Model modelPath={currentModel} />

      {/* Camera Controls */}
      <OrbitControls enableZoom={true} />
    </Canvas>
  );
}

export default ModelViewer;
