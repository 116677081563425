import React, { useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel CSS
import { Carousel } from "react-responsive-carousel";
import ModelViewer from "./ModelViewer";
import Navbar from "scenes/navbar";
import Footer from "scenes/footer";
import CoffeeWidget from "BuyMeACofeeWidget/coffeeWidget";

const HomePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isSmallScreen = useMediaQuery("(max-width: 1050px)");

  const hasAnimated = useRef(false);

  useEffect(() => {
    AOS.init({ once: true });

    const removeAosAttributes = () => {
      if (!hasAnimated.current) {
        hasAnimated.current = true;
        const elements = document.querySelectorAll("[data-aos]");
        elements.forEach((element) => {
          element.removeAttribute("data-aos");
          element.removeAttribute("data-aos-delay");
          element.removeAttribute("data-aos-duration");
        });
      }
    };

    setTimeout(removeAosAttributes, 1500);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {/* Blurred and semi-transparent background image */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(https://steamuserimages-a.akamaihd.net/ugc/2193876107321240926/4F3A2D9E8A51B390FC2B77407F9CBF0E60F555B9/?imw=5000&imh=5000&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(8px)",
          opacity: 0.35,
          zIndex: 0,
        }}
      />

      {/* Navbar with Increased Z-Index */}
      <Box sx={{ position: "relative", zIndex: 3 }}>
        <Navbar />
      </Box>

      {/* Main Content Row */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "2rem",
          flexGrow: 1,
          position: "relative",
          zIndex: 2,
        }}
      >
        {/* Left-Aligned Text Container */}
        <Box
          sx={{
            maxWidth: "700px",
            padding: "1rem",
            paddingLeft: "2rem",
          }}
          data-aos="fade-up"
        >
          <Typography variant="h1" fontWeight="bold" color="primary">
            Welcome to CraftsDB
          </Typography>
          <Typography
            variant="h5"
            sx={{ marginTop: "0.5rem", fontSize: "1.4rem" }}
            color="textSecondary"
          >
            Your ultimate resource for CS2 sticker crafts. At CraftsDB, we track
            and showcase rare and one-of-a-kind sticker crafts that are unlike
            anything you’ve seen. Whether you're a dedicated collector or just
            getting into the world of CS2, discover stunning, unique 1-of-1
            crafts. Dive deep, explore, and find a treasure for yourself at
            CraftsDB, your new go-to for all things CS2!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => {
              window.location.href = "/crafts";
            }}
          >
            Explore Now
          </Button>
        </Box>
      </Box>

      {/* Right-Aligned 3D Model Container, Hidden on Smaller Screens */}
      {!isSmallScreen && (
        <Box
          sx={{
            position: "absolute",
            top: "10rem",
            right: "2rem",
            width: "30vw",
            height: "30vw",
            maxWidth: "750px",
            maxHeight: "750px",
            zIndex: 2,
          }}
        >
          <ModelViewer />
        </Box>
      )}

      {/* Image Slider Section with Mobile Centering */}
      <Box
        sx={{
          width: "calc(60% + 20vw)", // Scales dynamically with viewport
          maxWidth: "850px", // Sets a maximum width for larger screens
          minWidth: "300px", // Ensures the slider doesn't get too small
          marginTop: "1rem",
          marginLeft: isMobile ? "auto" : "3.9rem", // Centers on mobile
          marginRight: isMobile ? "auto" : "auto", // Centers on mobile
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
          zIndex: 2,
          position: "relative",
        }}
        data-aos="fade-up"
      >
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          interval={3000}
        >
          <div>
            <img
              src="https://pbs.twimg.com/media/GbySkDnW0CAFfx9?format=jpg&name=4096x4096"
              alt="Image 1"
            />
          </div>
          <div>
            <img
              src="https://pbs.twimg.com/media/GbySo0GXIBI9WN-?format=jpg&name=4096x4096"
              alt="Image 2"
            />
          </div>
          <div>
            <img
              src="https://pbs.twimg.com/media/GbySqPhWEAUvRoq?format=jpg&name=4096x4096"
              alt="Image 3"
            />
          </div>
        </Carousel>
      </Box>

      {/* Footer */}

      <Footer sx={{ marginTop: "auto" }} />

      {/* Buy Me a Coffee Widget */}
      <CoffeeWidget />
    </Box>
  );
};

export default HomePage;
