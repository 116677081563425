import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Button,
  useMediaQuery,
} from "@mui/material";
import Lottie from "react-lottie";
import Navbar from "scenes/navbar";
import Footer from "scenes/footer";
import CoffeeWidget from "BuyMeACofeeWidget/coffeeWidget";
import "./Weapons.css";

const Weapons = () => {
  const [weapons, setWeapons] = useState([]);
  const [filteredWeapons, setFilteredWeapons] = useState([]);
  const [loadingAnimationData, setLoadingAnimationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [showOnlyOneOfOne, setShowOnlyOneOfOne] = useState(false);
  const [sortOption, setSortOption] = useState("highToLow");
  const [ownershipFilter, setOwnershipFilter] = useState("All");
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const sticker = searchParams.get("sticker");
  const category = searchParams.get("category");
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchAnimation = async () => {
      try {
        const response = await fetch("/LoadingAnimation.json");
        const data = await response.json();
        setLoadingAnimationData(data);
      } catch (error) {
        console.error("Error loading animation:", error);
      }
    };
    fetchAnimation();
  }, []);

  useEffect(() => {
    const fetchWeapons = async () => {
      setIsLoading(true);
      let url = `http://api.krisgfx.com/api/weapons?category=${category}`;

      if (sticker) {
        url += `&sticker=${sticker}`;
      }
      if (sticker === "102" && ownershipFilter !== "All") {
        url += `&filter=${ownershipFilter}`;
      }

      try {
        const response = await fetch(url);
        const data = await response.json();
        setWeapons(data);
        setFilteredWeapons(data);

        const imagePromises = data.map(
          (weapon) =>
            new Promise((resolve) => {
              const img = new Image();
              img.src = weapon.image_url || "path-to-default-weapon-image.jpg";
              img.onload = resolve;
              img.onerror = resolve;
            })
        );

        await Promise.all(imagePromises);
        setImagesLoaded(true);
      } catch (error) {
        console.error("Error fetching weapons:", error);
      } finally {
        setTimeout(() => setIsLoading(false), 500);
      }
    };

    if (category) {
      fetchWeapons();
    }
  }, [category, sticker, ownershipFilter]);

  useEffect(() => {
    let updatedWeapons = [...weapons];
    if (showOnlyOneOfOne) {
      updatedWeapons = updatedWeapons.filter((weapon) => weapon.quantity === 1);
    }
    updatedWeapons.sort((a, b) =>
      sortOption === "highToLow"
        ? b.quantity - a.quantity
        : a.quantity - b.quantity
    );

    setFilteredWeapons(updatedWeapons);
  }, [weapons, showOnlyOneOfOne, sortOption]);

  const handleOpenSite = (weapon) => {
    const name = encodeURIComponent(weapon.weapon_name);
    const defIndex = weapon.def_index;
    const paintIndex = weapon.paint_index;
    const min = weapon.min_float;
    const max = weapon.max_float;
    const stickers = encodeURIComponent(`[{"i":"${sticker}"}]`);
    const url = `https://csfloat.com/db?name=${name}&defIndex=${defIndex}&paintIndex=${paintIndex}&min=${min}&max=${max}&stickers=${stickers}`;
    window.open(url, "_blank");
  };

  const iconStyles = {
    width: "40px",
    height: "40px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    bottom: "10px",
    right: "10px",
    "&:hover": {
      transform: "scale(1.2)",
    },
    transition: "transform 0.3s",
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      className="weapons-grid-background"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      <Box sx={{ padding: "2rem", flex: 1 }}>
        <Button
          sx={{
            mb: 2,
            fontSize: "2rem",
            width: "88px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: 0,
          }}
          variant="contained"
          color="primary"
          onClick={() => navigate(-1)}
        >
          &#8592;
        </Button>

        {isLoading || !imagesLoaded ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              opacity: isLoading ? 1 : 0,
              transition: "opacity 0.5s ease",
            }}
          >
            {loadingAnimationData && (
              <Lottie options={lottieOptions} height={200} width={200} />
            )}
          </Box>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Weapons for {category}
              {/* Weapons for {category} {sticker && `with Sticker ID: ${sticker}`} */}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOnlyOneOfOne}
                    onChange={() => setShowOnlyOneOfOne(!showOnlyOneOfOne)}
                  />
                }
                label="Show Only 1/1"
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "row",
                  justifyContent: isMobile ? "space-between" : "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {sticker === "102" && (
                  <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Ownership</InputLabel>
                    <Select
                      value={ownershipFilter}
                      onChange={(e) => setOwnershipFilter(e.target.value)}
                      label="Ownership"
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="own">Own</MenuItem>
                      <MenuItem value="dontOwn">Don’t Own</MenuItem>
                    </Select>
                  </FormControl>
                )}

                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel>Sort by Quantity</InputLabel>
                  <Select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    label="Sort by Quantity"
                  >
                    <MenuItem value="highToLow">High to Low</MenuItem>
                    <MenuItem value="lowToHigh">Low to High</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Grid container spacing={2}>
              {filteredWeapons.length > 0 ? (
                filteredWeapons.map((weapon, index) => {
                  let hoverColor = "inherit";
                  if (weapon.iOwn === 1) {
                    hoverColor = "#04A777";
                  } else if (weapon.dontOwn === 1) {
                    hoverColor = "#F67E7D";
                  }

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={weapon.weapon_name || index}
                    >
                      <Card
                        sx={{
                          cursor: "pointer",
                          transition: "background-color 0.3s, transform 0.3s",
                          position: "relative",
                          padding: "1rem",
                          borderRadius: "12px",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          border: "1px solid #e0e0e0",
                          "&:hover": {
                            backgroundColor: hoverColor,
                            transform: "translateY(-5px)",
                            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.15)",
                          },
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "375px",
                          textAlign: "center",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={
                            weapon.image_url ||
                            "path-to-default-weapon-image.jpg"
                          }
                          alt={weapon.weapon_name || "Unknown Weapon"}
                          sx={{
                            height: "150px",
                            width: "auto",
                            marginBottom: "0.5rem",
                            objectFit: "contain",
                          }}
                        />
                        <CardContent sx={{ padding: 0 }}>
                          <Typography
                            variant="h6"
                            sx={{ fontSize: "1.1rem", fontWeight: "bold" }}
                          >
                            {weapon.weapon_name || "Unnamed Weapon"}
                          </Typography>
                          {weapon.doesntExist ? (
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "0.95rem" }}
                            >
                              Doesn't exist yet
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "0.95rem" }}
                            >
                              Quantity: {weapon.quantity ?? 0}
                            </Typography>
                          )}
                        </CardContent>

                        <Box
                          sx={iconStyles}
                          onClick={() => handleOpenSite(weapon)}
                        >
                          <img
                            src="https://csfloat.com/assets/n-mini-logo.png"
                            alt="Go to CSFloat"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </Box>
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                <Typography variant="body2" color="error">
                  No weapons found for this category.
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Box>
      <CoffeeWidget />
      <Footer />
    </Box>
  );
};

export default Weapons;
