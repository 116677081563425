import React from "react";
import Navbar from "scenes/navbar";
import Footer from "scenes/footer";
import CoffeeWidget from "BuyMeACofeeWidget/coffeeWidget";
import { useTheme } from "@mui/material/styles";
import styles from "./Partners.module.css"; // Import the updated CSS
import { FaGlobe } from "react-icons/fa"; // Social media icons
import { TbBrandX } from "react-icons/tb"; // X (formerly Twitter) icon

const Partners = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <div
      className={styles.partnersPage}
      data-theme={isDarkMode ? "dark" : "light"}
      style={{
        backgroundColor: isDarkMode ? "#0A0A0A" : "#f5f5f5",
        color: isDarkMode ? "#ffffff" : "#000000",
      }}
    >
      <Navbar />

      <section className={styles.container}>
        {/* Partner Section */}
        <div className={styles.section}>
          <h5 className={styles.categoryTitle}>Partner</h5>
          <div
            className={styles.card}
            style={{
              backgroundColor: isDarkMode ? "#ffffff" : "#333333",
              color: isDarkMode ? "#000000" : "#ffffff",
            }}
          >
            <div className={styles.content}>
              <div
                className={styles.logoContainer}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  className={styles.logo}
                  src="https://krisgfx.com/Partners/csBluegemLogo.png"
                  alt="CSBLUEGEM"
                  style={{ width: "16.5%" }}
                />
                <span
                  className={styles.logoText}
                  style={{
                    marginLeft: "1rem",
                    marginBottom: "0.6em",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: isDarkMode ? "#000000" : "#fff",
                  }}
                >
                  CSBLUEGEM
                </span>
              </div>
              <h6 className={styles.h6_partners}>
                All the data you need for Blue Gems
              </h6>
              <div className={styles.hoverContent}>
                <a
                  href="https://twitter.com/csbluegem"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.socialLink}
                >
                  <TbBrandX size={24} title="X (formerly Twitter)" />
                </a>
                <a
                  href="https://csbluegem.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.socialLink}
                >
                  <FaGlobe size={24} title="Website" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Contributor Section */}
        <div className={styles.section}>
          <h5 className={styles.categoryTitle}>Contributor</h5>
          <div
            className={styles.card}
            style={{
              backgroundColor: isDarkMode ? "#ffffff" : "#333333",
              color: isDarkMode ? "#000000" : "#ffffff",
            }}
          >
            <div className={styles.content}>
              <div
                className={styles.logoContainer}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  className={styles.logo}
                  src="https://krisgfx.com/Partners/minePhucLogo.png"
                  alt="MinePhuc"
                  style={{ width: "16.5%" }}
                />
                <span
                  className={styles.logoText}
                  style={{
                    marginLeft: "1rem",
                    marginBottom: "0.6em",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: isDarkMode ? "#000000" : "#fff",
                  }}
                >
                  MinePhuc
                </span>
              </div>
              <h6 className={styles.h6_partners}>
                {" "}
                for all your artworks and render needs
              </h6>
              <div className={styles.hoverContent}>
                <a
                  href="https://x.com/MinephucI"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.socialLink}
                >
                  <TbBrandX size={24} title="X (formerly Twitter)" />
                </a>
                <a
                  href="https://minephuci.notion.site/Portfolio-605cef9355f5418ba4b548cc0075ec5d"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.socialLink}
                >
                  <FaGlobe size={24} title="Website" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={styles.coffeeWidget}>
        <CoffeeWidget />
      </div>

      <Footer />
    </div>
  );
};

export default Partners;
